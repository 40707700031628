<template>
    <div>
        <van-cell title="会议分类" :value="model.CAT" />
        <van-cell title="会议主题" :value="model.TITLE" />
        <van-cell title="会议地点" :value="model.LOC" />
        <van-cell title="开会时间" :value="model.TIME | datetime('YYYY-MM-DD HH:mm')" />
        <van-cell title="主持人" :value="model.HOST" />


        <!-- <van-cell-group title="附加信息"> -->
        <van-field type="textarea" label="会议内容" v-model="model.CONT" placeholder="会议主要内容" row="5" show-word-limit
            maxlength="1000" />

        <van-cell title="出席人" v-model="model.CNT_JOIN" @click="tea.show = true" is-link />
        <van-popup v-model="tea.show" round position="bottom" closeable @close="countJoin">
            <div style="padding: 10px">
                <van-row>
                    <van-checkbox v-model="tea.all" @change="teaCheckAll"><b>全选</b></van-checkbox>
                    <van-divider />
                </van-row>
                <van-row>
                    <van-collapse v-model="tea.activeName" v-for="it in tea.dat" :key="it.group" accordion>
                        <van-collapse-item :title="it.group" :name="it.group">
                            <van-col :span="8" v-for="tea in it.lst" :key="tea.ID" style="margin: 10px 0">
                                <van-checkbox :name="tea.ID" v-model="tea.checked">{{ tea.NAME }}</van-checkbox>
                            </van-col>
                        </van-collapse-item>
                    </van-collapse>
                </van-row>
            </div>
        </van-popup>
        <van-cell-group title="上传照片">
            <div style="padding: 15px">
                <van-row>
                    <van-uploader multiple v-model="fileList" :max-count="10" :after-read="afterRead" @delete="del"
                        accept="image/*" />
                </van-row>
            </div>
        </van-cell-group>
        <!-- </van-cell-group> -->
        <div style="margin: 15px">
            <van-button type="primary" size="large" icon="success" block @click="submit" :loading="submitting"
                loading-text="正在提交。。。">确认提交</van-button>
            <br />
            <van-button plain type="default" size="large" icon="cross" block @click="$router.go(-1)">取消返回</van-button>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
import moment from "moment";
export default {
    name: "edit",
    data() {
        return {
            model: { CNT_JOIN: 0 },
            cat: {
                show: false,
                dat: []
            },
            loc: {
                show: false,
                dat: []
            },
            time: {
                show: false,
                minDate: moment().add(-1, 'y').toDate(),
                curDate: moment().toDate(),
            },
            tea: {
                show: false,
                dat: [],
                ret: [],
                all: false,
                activeName: []
            },
            submitting: false,
            lst: [],
            fileList: [],
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            let self = this;
            let g = this.$route.query.guid;

            if (g !== undefined) {
                this.whale.remote.getResult({
                    url: "/api/Mobile/MEET/MinfoApi/Get",
                    data: { GUID: g },
                    completed(m) {
                        self.tea.dat.map(o => {
                            if (m.DATA.JOINS.indexOf(',' + o.ID + ',') >= 0) {
                                //o.checked = true
                                self.tea.ret.push(o.ID)
                            }
                        })
                        m.DATA.LST_PIC_URL.map((o) => {
                            self.fileList.push({ url: o.Value, key: o.Key });
                        });
                        self.lst = m.DATA.JOINS.split(',')
                        self.model = m.DATA;
                        self.getPeoPle()
                    }
                })
            } else {
                self.getPeoPle()
                self.getPic()
            }

            // this.whale.remote.getCollection({
            //     url: "/api/Mobile/FX/DictApi/GetList",
            //     data: { TYPE: "MEET" },
            //     completed(its) {
            //         its.ITEMS.map(o => {
            //             if (o.NAME == "MEET_CAT") self.cat.dat.push(o.VALUE)
            //             else if (o.NAME == "MEET_LOC") self.loc.dat.push(o.VALUE)
            //         })
            //         self.model.CAT = self.cat.dat[0];
            //         self.model.LOC = self.loc.dat[0];
            //     }
            // })


        },
        getPeoPle() {
            let self = this;
            this.whale.remote.getCollection({
                url: '/api/Mobile/ORG/TeacherApi/GetList',
                completed(its) {
                    let t = {}
                    console.log(self.lst)
                    if (its.ITEMS.length == self.lst.length) {
                        self.tea.all = true
                    }
                    its.ITEMS.map(o => {
                        console.log(self.lst.indexOf(o.ID + ''))
                        if (self.lst.indexOf(o.ID + '') != -1) {
                            o.checked = true
                        } else {
                            o.checked = false;
                        }
                    });
                    console.log('123', its.ITEMS)
                    its.ITEMS.map(o => {
                        if (t[o.DEPT_NAME]) t[o.DEPT_NAME].push(o)
                        else t[o.DEPT_NAME] = [o]
                    });

                    let arr = [];
                    for (let o in t) {
                        arr.push({
                            group: o,
                            lst: t[o],
                            all: false
                        })
                    }
                    console.log('arr', arr)
                    self.tea.dat = arr;
                }
            })
        },
        teaCheckAll() {
            //this.$refs.cb_tea_all.toggleAll(this.tea.all);
            this.tea.dat.map(g => {
                g.all = this.tea.all;
                g.lst.map(o => {
                    o.checked = this.tea.all
                })
            })
        },
        countJoin() {
            let n = 0;
            this.tea.ret = [];
            this.tea.dat.map(g => {
                g.lst.map(o => {
                    if (o.checked) {
                        this.tea.ret.push(o.ID)
                        n++;
                    }
                })
            })
            this.model.CNT_JOIN = n;
        },

        getPic() {
            let self = this;
            let g = this.$route.query.guid;
            if (g !== undefined) {
                this.whale.remote.getResult({
                    url: "/api/Mobile/MEET/MinfoApi/Get",
                    data: { GUID: g },
                    completed(m) {
                        self.model = m.DATA;
                        console.log(m.DATA.LST_PIC_URL)
                        m.DATA.LST_PIC_URL.map((o) => {
                            self.fileList.push({ url: o.Value, key: o.Key });
                        });
                    },
                });
            }
        },
        afterRead(f) {
            console.log("AR", f);
            let self = this;
            f.status = "uploading";
            f.message = "上传中...";
            if (Array.isArray(f)) {
                f.forEach((item) => {
                    this.whale.image.compress({
                        file: item.file,
                        callback(cf) {
                            self.whale.image.toB64({
                                file: cf,
                                callback(b64) {
                                    self.whale.remote.getResult({
                                        url: "/api/Mobile/MEET/MinfoApi/UpPicture",
                                        data: {
                                            GUID: self.$route.query.guid,
                                            B64: b64,
                                        },
                                        finally() {
                                            item.status = "done";
                                        },
                                        completed(m) {
                                            item.key = m;
                                            item.status = "done";
                                        },
                                    });
                                },
                            });
                        },
                    });
                });
            } else {
                this.whale.image.compress({
                    file: f.file,
                    callback(cf) {
                        self.whale.image.toB64({
                            file: cf,
                            callback(b64) {
                                self.whale.remote.getResult({
                                    url: "/api/Mobile/MEET/MinfoApi/UpPicture",
                                    data: {
                                        GUID: self.$route.query.guid,
                                        B64: b64,
                                    },
                                    finally() {
                                        f.status = "done";
                                    },
                                    completed(m) {
                                        f.key = m;
                                        f.status = "done";
                                    },
                                });
                            },
                        });
                    },
                });
            }
        },
        del(f) {
            let self = this;
            this.whale.remote.getResult({
                url: "/api/Mobile/MEET/MinfoApi/DelPicture",
                data: {
                    GUID: self.$route.query.guid,
                    PIC: f.key.DATA,
                },
            });
        },

        submit() {
            if (this.model.TIME == undefined) {
                Toast.fail("请选择开会时间")
                return
            }
            let self = this;
            if (this.tea.ret.length) {
                this.model.JOINS = this.tea.ret.join(',');
            }
            // let data = {
            //     CAT: this.model.CAT,
            //     CNT_JOIN: this.model.CNT_JOIN,
            //     CONT: this.model.CONT,
            //     HOST: this.model.HOST,
            //     JOINS: this.model.JOINS,
            //     LOC: this.model.LOC,
            //     TIME: this.model.TIME,
            //     TITLE: this.model.TITLE,
            // }
            self.submitting = true;
            this.whale.remote.getResult({
                url: "/api/Mobile/MEET/MinfoApi/Save",
                data: self.model,
                finally() { self.submitting = false },
                completed() {
                    self.$dialog.alert({
                        message: "记录已成功提交！"
                    }).then(() => {
                        self.$router.go(-1);
                    })
                }
            })
        }
    }
}
</script>
<style scoped></style>